import React, { useEffect, useState } from 'react';
import { Calendar, Badge } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import locale from 'antd/es/date-picker/locale/he_IL';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button';
import NewEvent from './NewEvent';
import { getEventsAction } from '../../data/modules/events/events.actions';
import { FlexDivCenter } from '../../components/Typography/Typography';
import WeboLoader from '../../components/WeboLoader/WeboLoader';

moment.locale();

export default () => {
  const [showModal, setShowModal] = useState(false);
  const [activeDate, setActiveDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(23);
  const [dataSource, setDataSource] = useState({});
  const { events } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getEventsAction(selectedYear)).finally((_) => {});
  }, [selectedYear]);

  useEffect(() => {
    setDataSource(events);
  }, [events]);

  return (
    <FlexDivCenter style={{ minHeight: '50vh' }}>
      {isLoading ? <WeboLoader style={{ fontSize: 150 }} /> : renderScreen()}
    </FlexDivCenter>
  );

  function renderScreen() {
    return (
      <div style={{ direction: 'rtl' }}>
        <Button type="primary" onClick={onAddNewEvent}>
          {t('add_new_event')}
        </Button>
        <Calendar
          onSelect={(e) => setSelectedYear(e._d.getYear().toString().slice(1))}
          locale={locale}
          dateCellRender={dateCellRender}
        />
        {showModal && (
          <Modal
            footer={null}
            title={t('add_new_event')}
            visible={showModal}
            onCancel={() => setShowModal(false)}
          >
            <NewEvent onFinish={() => setShowModal(false)} date={activeDate} />
          </Modal>
        )}
      </div>
    );
  }

  function renderRow(day) {
    if (day.start) {
      return `${day.dayType} - כניסה ${moment(day.start).format('HH:mm')}`;
    }
    if (day.end) {
      console.log(moment(day.end));
      return `${day.dayType} - יציאה ${moment(day.end).format('HH:mm')}`;
    }
    return day.dayType;
  }

  function getListData(value) {
    const month = value.month() + 1;
    const day = value.date();
    return dataSource[month] && dataSource[month][day]
      ? [{ type: 'success', content: renderRow(dataSource[month][day]) }]
      : [];
  }

  function onAddNewEvent(e, value) {
    setActiveDate(moment(value).format('DD-MM-YY'));
    setShowModal(true);
  }

  function dateCellRender(value) {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <FlexDivCenter>
              <Badge status={item.type} text={item.content} />
            </FlexDivCenter>
          </li>
        ))}
      </ul>
    );
  }
};
