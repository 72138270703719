import {
  getEvents
} from "./events.service";
import {
  GETEVENTS_START,
  GETEVENTS_SUCCESS,
  GETEVENTS_FAILED
} from "./events.types";

//! PLOP_APPEND_ACTION

export function getEventsAction(year) {
  return async function _(dispatch) {
    const promise = getEvents(year); // modify by your logic
    dispatch({
      type: [
        GETEVENTS_START,
        GETEVENTS_SUCCESS,
        GETEVENTS_FAILED
      ],
      payload: promise
    });
    return promise;
  };
}