import { GETEVENTS_SUCCESS } from "./events.types";

const defaultState = {
  events: {}
};

function eventsReducer(state = defaultState, action) {
  switch (action.type) {

    //! PLOP_APPEND_REDUCER

    case GETEVENTS_SUCCESS: {
      return { ...state, events: action.payload || {} }; // add your logic here
    }
    default: {
      return state;
    }
  }
}

export default eventsReducer;