import { combineReducers } from "redux";
//! PLOP MAIN_REDUCERS_IMPORT
import events from "./modules/events/events.reducer";

import auth from "./modules/auth/auth.reducer";

export default combineReducers({
  //! PLOP MAIN_REDUCERS_APPEND
  events,
  auth
});
