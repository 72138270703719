import styled from 'styled-components';
import { TimePicker as AntTimePicker , InputNumber as AntInputNumber } from 'antd'
  ;


export const InputNumber = styled(AntInputNumber)`
margin: 0 25px;
`;

export const TimePicker = styled(AntTimePicker)`
margin: 0 25px;
.ant-time-picker-icon {
  display: none;
}

.anticon-close-circle  {
  display: none !important;
}
`;