import moment from 'moment';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Table, Button, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { monthDaysMapper, columns, calculateHoliday } from './utils';
import { Text, FlexDivCenter } from '../../components/Typography/Typography';
import WeboLoader from '../../components/WeboLoader/WeboLoader';
import { getEventsAction } from '../../data/modules/events/events.actions';
import { formatCurrency } from '../utils';

const OPTIONS = [100, 125, 150, 175, 200, 225, 250];

export default () => {
  const { t } = useTranslation();
  const [times, setTimes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { events } = useSelector((state) => state.events);
  const [employeeName, setEmployeeName] = useState('');
  const [employeeSalary, setEmployeeSalary] = useState();
  const [employeeId, setEmployeeId] = useState('');
  const [results, setResults] = useState();
  const dispatch = useDispatch();
  const ref = useRef();
  const [isFirstCalc, setIsFirstCalc] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getEventsAction(23)).then((res) => {
      console.log({ selectedMonth });
      const currentEvents = get(res, [selectedMonth]);
      if (!currentEvents) {
        setTimes([]);
        return setIsLoading(false);
      }
      setTimes(
        [...new Array(monthDaysMapper[selectedMonth])].map((v, idx) => {
          return {
            day: idx + 1,
            start: moment('08:00', 'HH:mm'),
            end: moment('16:00', 'HH:mm'),
            br: 15,
            dayOff: false,
            isWorking: true,
            typeObject: currentEvents[idx + 1],
          };
        })
      );
      setIsLoading(false);
    });
  }, [selectedMonth]);

  function calculateSpecialDay({
    specialDay,
    totalResMinutesDaily,
    dayTotalInMinutes,
    start,
    end,
  }) {
    console.log({ dayTotalInMinutes });
    const tenPM = moment(start).format('MM/DD/YY 22:00');
    const sixAM = moment(start).format('MM/DD/YY 06:00');
    const eightOrSevenAccordingToNight =
      end.diff(tenPM) / 60 / 60 / 1000 >= 2 ||
      start.diff(sixAM) / 60 / 60 / 1000 <= -2
        ? 7
        : 8;
    // ? -------- FULL HOLIDAY WITHOUT START / END -------- //
    if (dayTotalInMinutes > 10 * 60) {
      totalResMinutesDaily[specialDay.percent + 50] +=
        dayTotalInMinutes - 10 * 60; // ?calculate the rest
      totalResMinutesDaily[specialDay.percent + 25] += 60 * 2; // ? must have 2 extra hours;
      totalResMinutesDaily[specialDay.percent] +=
        60 * eightOrSevenAccordingToNight; // ? must have 8 regular hours;
    } else if (dayTotalInMinutes > eightOrSevenAccordingToNight * 60) {
      totalResMinutesDaily[specialDay.percent + 25] +=
        dayTotalInMinutes - eightOrSevenAccordingToNight * 60;
      totalResMinutesDaily[specialDay.percent] +=
        60 * eightOrSevenAccordingToNight; // ? must have 8 regular hours;
    } else {
      // should pay regular 100%
      totalResMinutesDaily[specialDay.percent] += dayTotalInMinutes;
    }
    return totalResMinutesDaily;
  }

  function calculateRegularDay({
    dayTotalInMinutes,
    totalResMinutesDaily,
    start,
    end,
  }) {
    const tenPM = moment(start).format('MM/DD/YY 22:00');
    const sixAM = moment(start).format('MM/DD/YY 06:00');
    const eightOrSevenAccordingToNight =
      end.diff(tenPM) / 60 / 60 / 1000 >= 2 ||
      start.diff(sixAM) / 60 / 60 / 1000 <= -2
        ? 7
        : 8;

    if (dayTotalInMinutes > 10 * 60) {
      // should pay 150% + 125% + 100%
      totalResMinutesDaily[150] += dayTotalInMinutes - 10 * 60; // ?calculate the rest
      totalResMinutesDaily[125] += 60 * 2; // ? must have 2 extra hours;
      totalResMinutesDaily[100] += 60 * eightOrSevenAccordingToNight; // ? must have 8 regular hours;
    } else if (dayTotalInMinutes > eightOrSevenAccordingToNight * 60) {
      totalResMinutesDaily[125] +=
        dayTotalInMinutes - eightOrSevenAccordingToNight * 60;
      totalResMinutesDaily[100] += 60 * eightOrSevenAccordingToNight; // ? must have 8 regular hours;
    } else {
      // should pay regular 100%
      totalResMinutesDaily[100] += dayTotalInMinutes;
    }
    return totalResMinutesDaily;
  }

  function onCalculateHours() {
    setIsFirstCalc(false);
    const totalResMinutesDaily = { 100: 0, 125: 0, 150: 0, 175: 0, 200: 0 };
    times.forEach((day) => {
      const calculatedDay = calculateExtraHoursPerDay(day);
      calculatedDay &&
        Object.keys(calculatedDay).map((dayPercents) => {
          totalResMinutesDaily[dayPercents] += calculatedDay[dayPercents];
        });
    });
    // const regularDays = calculateRegularDaysInMinutes();
    // const finalResult = calculateSpecialDaysInMinutes(regularDays);
    setResults(totalResMinutesDaily);
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 0);
    // window.scrollTo(0, finishRef.current.offsetTop);
  }

  function onTimeChange(type, newTime, day) {
    if (!newTime) return;
    const cloned = [...times];
    cloned[day - 1][type] = moment(newTime._d);
    setTimes(cloned);
  }

  function handleDayOff(day) {
    const cloned = [...times];
    cloned[day - 1].dayOff = !times[day - 1].dayOff;
    setTimes(cloned);
  }

  function handleIsWorking(day) {
    const cloned = [...times];
    cloned[day - 1].isWorking = !times[day - 1].isWorking;
    setTimes(cloned);
  }

  function onBreakChange(day, value) {
    const cloned = [...times];
    cloned[day - 1].br = value;
    setTimes(cloned);
  }

  function takeFromHoliday(day, take) {
    const cloned = [...times];
    cloned[day - 1].take = take;
    setTimes(cloned);
  }

  function printClick() {
    document.getElementById('ant-table').style.width = '70vw';
    document.getElementById('ant-table').style.fontWeight = 'bold';
    document.getElementById('my-table').style.width = '80vw';
    window.print();
  }

  function calculateExtraHoursPerDay({
    end,
    start,
    dayOff,
    isWorking,
    br,
    day,
    take,
  }) {
    const specialDay = events[selectedMonth][day];
    const totalResMinutesDaily = { 100: 0, 125: 0, 150: 0, 175: 0, 200: 0 };
    if (dayOff || !isWorking) return;
    // ? -------- SEMI HOLIDAY WITH START ONLY -------- // TODO!
    if (specialDay && specialDay.start && !specialDay.end) {
      const specialA = calculateHoliday(
        moment(specialDay.start),
        start,
        end,
        true
      );
      if (specialA) {
        if (specialA.holiday >= br && !take) {
          specialA.holiday -= br;
        } else {
          specialA.regular -= br;
        }
        calculateRegularDay({
          specialDay,
          totalResMinutesDaily,
          dayTotalInMinutes: specialA.regular,
          start,
          end,
        });
        calculateSpecialDay({
          specialDay,
          totalResMinutesDaily,
          dayTotalInMinutes: specialA.holiday,
          start,
          end,
        });
      }
      return totalResMinutesDaily;
      // ? -------- SEMI HOLIDAY WITH END ONLY -------- // TODO!
    }
    if (specialDay && !specialDay.start && specialDay.end) {
      const specialB = calculateHoliday(
        moment(specialDay.end),
        start,
        end,
        false
      );
      if (specialB) {
        if (specialB.holiday >= br && !take) {
          specialB.holiday -= br;
        } else {
          specialB.regular -= br;
        }
        calculateRegularDay({
          specialDay,
          totalResMinutesDaily,
          dayTotalInMinutes: specialB.regular,
          start,
          end,
        });
        calculateSpecialDay({
          specialDay,
          totalResMinutesDaily,
          dayTotalInMinutes: specialB.holiday,
          start,
          end,
        });
      }
      return totalResMinutesDaily;
    }
    let dayTotalInMinutes = end.diff(start) / 60 / 1000 - br;
    if (dayTotalInMinutes <= 0) {
      const endddd = end.add(1, 'days');
      dayTotalInMinutes = endddd.diff(start) / 60 / 1000 - br;
    }
    // ? -------- FULL HOLIDAY WITHOUT START / END -------- //
    if (specialDay && !specialDay.start && !specialDay.end) {
      return calculateSpecialDay({
        specialDay,
        totalResMinutesDaily,
        dayTotalInMinutes,
        start,
        end,
      });
    } // ? -------- FULL REGULAR DAY -------- //
    return calculateRegularDay({
      specialDay,
      dayTotalInMinutes,
      start,
      end,
      totalResMinutesDaily,
    });
  }

  const renderData = useMemo(() => {
    return (
      <div id="my-table" style={{ width: '80%' }}>
        <FlexDivCenter style={{ margin: 30 }}>
          <Select
            size="large"
            value={selectedMonth}
            onSelect={setSelectedMonth}
            style={{ width: 200, fontSize: 24 }}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
              <Select.Option key={month} value={month}>
                {t(month)}
              </Select.Option>
            ))}
          </Select>
        </FlexDivCenter>
        <div className="no-print">
          <FlexDivCenter>
            <Input
              onChange={({ target }) => setEmployeeSalary(target.value)}
              type="number"
              size="large"
              style={{ width: '115px', margin: 10 }}
              placeholder={t('employee_salary')}
            />
            <Input
              onChange={({ target }) => setEmployeeName(target.value)}
              size="large"
              style={{ width: '200px', margin: 10 }}
              placeholder={t('employee_name')}
            />
            <Input
              onChange={({ target }) => setEmployeeId(target.value)}
              type="number"
              size="large"
              style={{ width: '200px', margin: 10 }}
              placeholder={t('employee_id')}
            />
          </FlexDivCenter>
        </div>
        <div ref={ref} style={{ display: 'flex', justifyContent: 'center' }}>
          <Table
            id="ant-table"
            locale={{ emptyText: t('update_holidays') }}
            pagination={{ showTotal: false, pageSize: 40 }}
            columns={columns({
              t,
              takeFromHoliday,
              selectedMonth,
              calculateExtraHoursPerDay,
              onTimeChange,
              onBreakChange,
              handleDayOff,
              handleIsWorking,
            })}
            dataSource={times}
          />
        </div>
        <div className="no-print">
          <FlexDivCenter style={{ margin: 35 }}>
            <Button
              onClick={onCalculateHours}
              shape="round"
              style={{
                width: 300,
                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
              }}
              type="primary"
              size="large"
            >
              {isFirstCalc ? t('calculate') : t('calculate_again')}
            </Button>
          </FlexDivCenter>
        </div>
        <FlexDivCenter>{renderResults()}</FlexDivCenter>
      </div>
    );
  }, [results, times]);

  function calculateSalary() {
    let sum = 0;
    OPTIONS.map((option) => {
      if (results[option]) {
        console.log(
          +results[option],
          '---',
          +employeeSalary,
          '---',
          +option / 100
        );
        sum += ((+results[option] / 60) * +employeeSalary * +option) / 100;
      }
    });
    return formatCurrency(sum);
  }

  function renderResults() {
    return !results ? null : (
      <div>
        <FlexDivCenter>
          <Text size="xxxlarge">{employeeName}</Text>
        </FlexDivCenter>
        <FlexDivCenter>
          <Text size="xxxlarge">{employeeId}</Text>
        </FlexDivCenter>
        {employeeSalary && (
          <Text style={{ margin: 30 }} size="large">
            {t('salary', { salary: calculateSalary() })}
          </Text>
        )}
        <FlexDivCenter>
          <Text size="xxxlarge">
            {t('days_sum', {
              value: times.reduce((acc, next) => {
                if (!next.dayOff && next.isWorking) {
                  return acc + 1;
                }
                return acc;
              }, 0),
            })}
          </Text>
        </FlexDivCenter>
        <FlexDivCenter>
          {OPTIONS.map((option) =>
            results[option] ? (
              <Text style={{ margin: 30 }} size="large">
                {`${option}%:`}{' '}
                <strong>{(results[option] / 60).toFixed(2)}</strong>
              </Text>
            ) : null
          )}
        </FlexDivCenter>
        <span className="no-print">
          <FlexDivCenter>
            <Button
              onClick={printClick}
              style={{ margin: 35 }}
              size="large"
              type="dashed"
            >
              {t('print')}
            </Button>
          </FlexDivCenter>
        </span>
      </div>
    );
  }

  return (
    <>
      <FlexDivCenter style={{ minHeight: '50vh' }}>
        {isLoading ? <WeboLoader style={{ fontSize: 150 }} /> : renderData}
      </FlexDivCenter>
    </>
  );
};
