import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Menu } from 'antd';
import { MainWrapper } from './Main.styled';
import { changeLanguage, getActiveLanguage } from '../../config/translations/utils';
import Card from '../../components/Card/Card';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import { Text, FlexDivCenter } from '../../components/Typography/Typography';
import { loginAction } from '../../data/modules/auth/auth.actions';
import illustration from '../../assets/illu.jpg';

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { activeUser } = useSelector(store => store.auth);
  const router = useHistory();


  async function onLogin() {
    try {
      dispatch(loginAction());
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <MainWrapper>
      {activeUser &&
        <>
          <Button margin="5px" onClick={() => router.push('/events')}>{t('calendar')}<Icon inButton type="event" /></Button>
          <Text size="xxxlarge">{t('welcome', { name: activeUser.displayName })}</Text>
        </>
      }
      <Text className="animated fadeInDown" size="title">{t('shain_maintanance')}</Text>
      <Text className="animated fadeInDown delay-1s" size="xxxlarge">{t('system_description')}</Text>
      <FlexDivCenter>
        <img className="animated fadeInUp" style={{ width: '40vw' }} src={illustration} alt="illustration" />
      </FlexDivCenter>
    </MainWrapper>
  );
};