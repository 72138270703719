import React, { useState } from 'react';
import { Route } from "react-router";
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import './App.css';
import { ConfigProvider, Menu } from 'antd';
import { Icon } from '@ant-design/compatible';
import { useTranslation } from 'react-i18next';
import Main from './containers/Main/Main';
import { AppWrapper } from './App.styled';
import createStore from './data/store';
import EventsCalendar from './containers/EventsCalendar/EventsCalendar';
import Calculation from './containers/Calculation/Calculation';
import shainLogo from './assets/shain.png';

const routes = [
  { path: "/", component: Main, isExact: true },
  { path: "/events", component: EventsCalendar, isExact: true },
  { path: "/calculation", component: Calculation, isExact: true },
  // { path: "/template", component: <div>template</div>, isExact: true }
];


function App() {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState('/CHANGE_ME');

  function changeRoute(route) {
    window.location = route;
    setActiveKey(route);
  }

  return (
    <ConfigProvider direction="rtl">
      <Provider store={createStore()}>
        <span className="no-print">
          <Menu selectedKeys={[activeKey]} mode="horizontal">
            <Menu.Item key="" onClick={() => changeRoute('/')}>
              <img height="50px" src={shainLogo} alt="shain" />
            </Menu.Item>
            <Menu.Item key="/" onClick={() => changeRoute('/')}>
              {t('home')}
            </Menu.Item>
            <Menu.SubMenu title={t('management')}>
              <Menu.ItemGroup title={t('management')}>
                <Menu.Item onClick={() => changeRoute('/events')} key="/events">{t('calendar')}</Menu.Item>
                <Menu.Item onClick={() => changeRoute('/calculation')} key="/calculation">{t('calculate')}</Menu.Item>
              </Menu.ItemGroup>
            </Menu.SubMenu>
          </Menu>
        </span>
        <AppWrapper>
          <BrowserRouter>
            {routes.map(route => (
              <Route
                exact={route.isExact}
                key={route.path}
                path={route.path}
                component={route.component}
              />
            ))
            }
          </BrowserRouter>
        </AppWrapper>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
