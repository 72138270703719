import moment from 'moment';
import React, { useState } from 'react';
import { DatePicker } from 'antd';
import { useDispatch } from 'react-redux';
import newlocale from 'antd/es/date-picker/locale/he_IL';
import { InputNumber } from './styles';
import Select from '../../components/Select/Select';
import Button from '../../components/Button/Button';
import Option from '../../components/Select/Option';
import {
  TextSpan,
  FlexDivCenter,
} from '../../components/Typography/Typography';
import { database } from '../../fb';
import { getEventsAction } from '../../data/modules/events/events.actions';

moment.locale();

export default ({ onSubmit, onFinish }) => {
  const [percent, setPercent] = useState(150);
  const [dayType, setDayType] = useState('שבת');
  const [eventStartDate, setEventStartDate] = useState();
  const [eventEndDate, setEventEndDate] = useState();
  const dispatch = useDispatch();

  function handleDateRangeChange(dates) {
    setEventStartDate(dates[0]);
    setEventEndDate(dates[1]);
  }

  async function onSubmit(e) {
    const evtObject = {
      dayType,
      percent,
    };
    const startMonth = moment(eventStartDate).format('YY/M');
    const endMonth = moment(eventEndDate).format('YY/M');
    console.log({
      eventStartDate: eventStartDate.format('MM/DD/YY HH:mm'),
      eventEndDate,
    });
    const startDay = eventStartDate.date();
    const endDay = eventEndDate.date();
    let i = 0;
    if (endDay === startDay && startMonth === endMonth) {
      await database.ref().update({
        [`events/${startMonth}/${startDay}`]: {
          ...evtObject,
          day: i,
          start: moment(eventStartDate).format('MM/DD/YY HH:mm'),
          end: moment(eventEndDate).format('MM/DD/YY HH:mm'),
        },
      });
    } else if (endDay > startDay) {
      await database.ref().update({
        [`events/${startMonth}/${startDay}`]: {
          ...evtObject,
          day: i,
          start: moment(eventStartDate).format('MM/DD/YY HH:mm'),
        },
      });
      for (i = startDay + 1; i < endDay; i++) {
        await database
          .ref()
          .update({ [`events/${startMonth}/${i}`]: { ...evtObject } });
      }
      await database.ref().update({
        [`events/${startMonth}/${i}`]: {
          ...evtObject,
          end: moment(eventEndDate).format('MM/DD/YY HH:mm'),
        },
      });
    } else {
      i = startDay;
      let j = 1;
      let date;
      await database.ref().update({
        [`events/${startMonth}/${i}`]: {
          ...evtObject,
          day: i,
          start: moment(eventStartDate).format('MM/DD/YY HH:mm'),
        },
      });
      i += 1;
      while (i !== endDay) {
        if (i < endDay + 1) {
          await database
            .ref()
            .update({ [`events/${endMonth}/${i}`]: { ...evtObject, day: i } });
        } else {
          await database.ref().update({
            [`events/${startMonth}/${i}`]: { ...evtObject, day: i },
          });
        }
        const m = moment(eventStartDate);
        date = moment(m.add(j, 'days'));
        i = date.date();
        j++;
      }
      if (i < endDay + 1) {
        await database.ref().update({
          [`events/${endMonth}/${i}`]: {
            ...evtObject,
            day: i,
            end: moment(eventEndDate).format('MM/DD/YY HH:mm'),
          },
        });
      } else {
        await database.ref().update({
          [`events/${startMonth}/${i}`]: {
            ...evtObject,
            day: i,
            end: moment(eventEndDate).format('MM/DD/YY HH:mm'),
          },
        });
      }
    }
    dispatch(getEventsAction(23)).then(onFinish);
  }

  return (
    <>
      <FlexDivCenter style={{ margin: 15 }}>
        <TextSpan margin="15">סוג האירוע</TextSpan>
        <Select onChange={setDayType} value={dayType} width="35%">
          <Option key="שבת">שבת</Option>
          <Option key="חג">חג</Option>
          <Option key="אחר">אחר</Option>
        </Select>
      </FlexDivCenter>
      <div style={{ direction: 'ltr', textAlign: 'center' }}>
        <DatePicker.RangePicker
          showTime={{ format: 'HH:mm' }}
          format="DD/MM/YY HH:mm"
          onOk={handleDateRangeChange}
          locale={newlocale}
        />
      </div>
      <FlexDivCenter style={{ margin: 15 }}>
        <TextSpan>אחוז תשלום באירוע</TextSpan>
        <InputNumber
          step={5}
          defaultValue={percent}
          min={100}
          max={200}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
          onChange={(p) => setPercent(p)}
        />
      </FlexDivCenter>
      <FlexDivCenter>
        <Button type="primary" margin="45px 0 0 0" onClick={onSubmit}>
          אישור
        </Button>
      </FlexDivCenter>
    </>
  );
};
