import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import webologo from '../../assets/webo-logo.png';

export default (props) => {
  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <img src={webologo} style={{ position: 'absolute', width: get(props, 'style.fontSize', 80) - 20 }} alt="webo" />
      <LoadingOutlined style={{ fontSize: get(props, 'style.fontSize', 80) }} />
    </div>
  );
};