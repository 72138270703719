import React from 'react';
import { TimePicker, InputNumber, Tag, Checkbox } from 'antd';
import { Icon } from '@ant-design/compatible';
import moment from 'moment';
import {
  TextSpan,
  Text,
  FlexDivCenter,
} from '../../components/Typography/Typography';

export const monthDaysMapper = {
  1: 31,
  2: 28,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};

const dayOffStyle = {
  background: 'red',
  padding: 10,
  borderRadius: 5,
  color: '#FFF',
  fontWeight: 600,
};
const isNotWorkingStyle = {
  background: 'blue',
  padding: 10,
  borderRadius: 5,
  color: '#FFF',
  fontWeight: 600,
};

function getRelevantStyle(day) {
  if (day.dayOff) return dayOffStyle;
  if (!day.isWorking) return isNotWorkingStyle;
  return {};
}
export const columns = ({
  takeFromHoliday,
  t,
  selectedMonth,
  onTimeChange,
  onBreakChange,
  handleDayOff,
  calculateExtraHoursPerDay,
  handleIsWorking,
}) => [
  {
    title: t('date'),
    dataIndex: 'day',
    key: 'day',
    width: 180,
    render: (day, { dayOff, isWorking, typeObject, isStart, start, end }) => (
      <TextSpan style={getRelevantStyle({ dayOff, isWorking })}>
        {`${day}/${selectedMonth}/${23}${dayOff ? ' חופש' : ''}${
          !isWorking ? ' מנוחה' : ''
        }`}
        {typeObject && (
          <Tag color="magenta">
            {typeObject.start && !typeObject.end
              ? `ע. ${typeObject.dayType}`
              : typeObject.dayType}
          </Tag>
        )}
      </TextSpan>
    ),
  },
  {
    title: t('start'),
    dataIndex: 'start',
    key: 'start',
    width: 250,
    render: (start, { day, isWorking, dayOff }) =>
      dayOff || !isWorking ? (
        '--------'
      ) : (
        <TimePicker
          onChange={(time) => onTimeChange('start', time, day)}
          size="large"
          defaultValue={start}
          format="HH:mm"
        />
      ),
  },
  {
    title: t('end'),
    dataIndex: 'end',
    key: 'end',
    width: 250,
    render: (end, { day, dayOff, isWorking }) =>
      dayOff || !isWorking ? (
        '--------'
      ) : (
        <TimePicker
          onChange={(time) => onTimeChange('end', time, day)}
          size="large"
          defaultValue={end}
          format="HH:mm"
        />
      ),
  },
  {
    title: t('break'),
    dataIndex: 'br',
    key: 'br',
    width: 250,
    render: (br, { day, typeObject, dayOff, isWorking }) =>
      dayOff || !isWorking ? (
        '--------'
      ) : (
        <div>
          <InputNumber
            step={5}
            onChange={(value) => value >= 0 && onBreakChange(day, value)}
            size="large"
            value={br}
          />
          {typeObject && (
            <Checkbox
              onChange={(e) => takeFromHoliday(day, e.target.checked)}
              style={{ marginRight: 5 }}
            />
          )}
        </div>
      ),
  },
  {
    title: t('sum'),
    dataIndex: 'sum',
    key: 'sum',
    width: 150,
    render: (value, { start, end, br, dayOff, isWorking }) => {
      if (end.diff(start) > 0) {
        return (
          <TextSpan>
            {end.diff(start) > 0 && !dayOff && isWorking
              ? `${timeConvert(end.diff(start) / 60 / 1000, br)} שעות עבודה`
              : '-------'}
          </TextSpan>
        );
      }
      return (
        <TextSpan>
          {end.add(1, 'days').diff(start) > 0
            ? `${timeConvert(end.diff(start) / 60 / 1000, br)} שעות עבודה`
            : '-------'}
        </TextSpan>
      );
    },
  },
  {
    title: t('extra_hours'),
    dataIndex: 'disableDay',
    key: 'disableDay',
    className: 'no-print',

    width: 150,
    render: (value, record) => {
      const calculated = calculateExtraHoursPerDay(record);
      return (
        calculated && (
          <div>
            {Object.keys(calculated)
              .filter((percent) => calculated[percent])
              .map((percent) => (
                <Text>
                  {`${timeConvert(calculated[percent].toFixed(2), 0)} :`}
                  <strong>{`${percent}%`}</strong>
                </Text>
              ))}
          </div>
        )
      );
    },
  },
  {
    title: t('actions'),
    dataIndex: 'disableDay',
    key: 'disableDay',
    className: 'no-print',

    width: 150,
    render: (value, { day, dayOff, isWorking }) => (
      <FlexDivCenter className="no-print">
        <TextSpan
          onClick={(e) => handleIsWorking(day)}
          style={{
            display: 'flex',
            marginBottom: 10,
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Icon
            type={!dayOff ? 'stop' : 'check-circle'}
            theme={!isWorking ? 'twoTone' : 'filled'}
            style={{ fontSize: 12, marginLeft: 5 }}
          />
          {isWorking ? 'סמן כיום מנוחה' : 'החזר ליום עבודה'}
        </TextSpan>
        <TextSpan
          className="no-print"
          onClick={(e) => handleDayOff(day)}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <Icon
            type={!dayOff ? 'clock-circle' : 'check-circle'}
            theme={!dayOff ? 'filled' : 'filled'}
            style={{ fontSize: 12, marginLeft: 5 }}
          />
          {!dayOff ? 'סמן כיום חופש' : 'החזר ליום עבודה'}
        </TextSpan>
      </FlexDivCenter>
    ),
  },
];

export function timeConvert(num, br) {
  const n = Math.round(num - br);
  const hours = Math.floor(n / 60);
  const minutes = (n % 60).toFixed(0);
  return `${hours}:${minutes}`;
}

export function minutesOfDay(m) {
  return m.minutes() + m.hours() * 60;
}

export function calculateHoliday(check, start, end, isStart) {
  const betweener = check.minutes() + check.hours() * 60;
  const startedAt = start.minutes() + start.hours() * 60;
  const finishedAt = end.minutes() + end.hours() * 60;
  const finalResult = { holiday: 0, regular: 0 };
  if (startedAt > finishedAt) {
    console.log({ check, start, end });
    // finishedAt += 24 * 60;
  }

  // const x = moment(start).format('MM/DD/YY 23:59');
  // const y = moment(start).format('MM/DD/YY 00:00');
  // const eleven = x.minutes() + x.hours() * 60;
  // const midnight = y.minutes() + y.hours() * 60;

  if (isStart) {
    if (betweener >= finishedAt) {
      // ? ALL IS REGULAR.
      finalResult.regular = finishedAt - startedAt;
    } else if (betweener >= startedAt && betweener <= finishedAt) {
      // ? IM IN CUT
      finalResult.holiday = finishedAt - betweener;
      finalResult.regular = betweener - startedAt;
    } else if (betweener <= startedAt) {
      // ? ALL IS SHABAT
      finalResult.holiday = finishedAt - startedAt;
    }
  } else if (betweener >= finishedAt) {
    // ? ALL IS SHABAT
    finalResult.holiday = finishedAt - startedAt;
  } else if (betweener >= startedAt && betweener <= finishedAt) {
    // ? IM IN CUT
    finalResult.regular = finishedAt - betweener;
    finalResult.holiday = betweener - startedAt;
  } else if (betweener >= startedAt) {
    finalResult.regular = finishedAt - startedAt; // ? ALL IS REGULAR
  }
  if (finalResult.holiday < 0) {
    finalResult.holiday += 24 * 60;
    const bla = finalResult.holiday;
    finalResult.holiday = finalResult.regular;
    finalResult.regular = bla;
  } else if (finalResult.regular < 0) {
    finalResult.regular += 24 * 60;
    const bla = finalResult.holiday;
    finalResult.holiday = finalResult.regular;
    finalResult.regular = bla;
  }
  return finalResult.regular || finalResult.holiday ? finalResult : false;
}
